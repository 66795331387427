<template>
  <div v-show="show">
      <input type="datetime-local" :min="currentTime" placeholder="-" v-model="startTime">
      <button @click="submit">-</button>
  </div>

<div class="richgangCutTimebg">
<p  class="RichgangWhitelist">- {{whiteMintTitle}} -</p>

  <div class="richgangCutTime">
      
        <div class="richgangCutTimeText"><span class="h">{{ countDownTime }}</span> : <span class="m"> {{MINUTES}}</span> : <span class="s">{{SECONDS}}</span>
            <div class="richigangtimename"><span class="HOURS">HOURS</span>  <span class="MINUTES">MINUTES</span>  <span class="SECONDS">SECONDS</span></div>
        </div>

         <div class="richgangPrice">
  <h2>price</h2>
  <h1>0.08ETH</h1>
  <h2>Minted</h2>
  <h1>{{instack}}/6666</h1>     
  </div>

  </div></div>

  <div class="whitelistinfo" v-show="whitelistedInfo"><span><img src="/img/home_tips_4x.png" /></span>Your address is not whitelisted</div>
 
</template>

<script>
let timer = null;
let tipTextPrefix = ' ';
import moment from "moment";
//import axios from 'axios';
export default {
    name: 'CountDown',
    data() { return {
        currentTime: moment().format('YYYY-MM-DDTHH:mm'), // 请使用步骤1-3计算出来的服务器时间
        startTime: moment().format('YYYY-MM-DDTHH:mm'),
        countDownTime: tipTextPrefix + '00',
        show:false,
        SECONDS:"00",
        MINUTES:"00",
        nowDate:"",
        momentNowdate:"",
        diffseconds:"",
        whiteMintTitle:"Richgang whitelist Mint"
    }},
    props:['whitelistedInfo','instack'],
    mounted(){
        this.startTime = "2022-03-27T20:00";
        this.startTime = "2022-03-27T13:25";
        this.setTime()
    },
    methods: {
        submit: function() {
            const _this = this;
            clearInterval(timer);
            timer = setInterval(() => {
                _this.countDownTime = _this.countDown();
            }, 1000);
        },
         setTime:async function()
        {
        //     this.nowDate = new Date;
        //     console.log(this.nowDate);
        //     await axios.get('http://richgang-env.eba-mjduf6h3.us-east-1.elasticbeanstalk.com/time.php').then(response => (
        //     console.log(response.data),
        //     this.nowDate = response.data
        //   )
        //   );
          this.nowDate = new Date;
          this.diffseconds = moment(this.startTime).diff(this.nowDate, 'seconds');         
        setTimeout(() => {
             this.submit();
        }, 1000);
        },
        countDown: function() {            
            // let seconds = moment(this.startTime).diff(this.nowDate, 'seconds');
            this.diffseconds = this.diffseconds -1;
            let seconds = this.diffseconds;
            seconds = 0;
            if (seconds <= 0) {
                clearInterval(timer);
                this.$emit( 'richgangWhiteMintStart','10000')
                this.whiteMintTitle ="Whitelist Mint in progress";
                this.startTime = "2022-03-28T20:00";
                this.setTime();
                return '00';
            }
            const second = seconds%60 > 9 ? seconds%60 :"0"+seconds%60;
            const minutes = (seconds-second) / 60;
            const minute = minutes%60 > 9 ?minutes%60 :"0" + minutes%60;
            const hours = (minutes-minute) / 60;
            const hour = hours%24;
            const day = (hours-hour) / 24;
            const res = (day*24+hour);
            this.MINUTES = minute;
            this.SECONDS = second;
            return res > 9 ? res:"0" + res;
        },
        watch:{

        }
    },
}
</script>

<style>

</style>
