<template>
  <div
    id="app"
    class="loading"
    v-show="this.loading"
    @click="hideLoading"
    :style="{ backgroundPosition: this.left + '%', opacity: opacity }"
  ></div>

  <div v-show="!display" style="height:800000px;"></div>

  <div v-show="display">
    <hero />
  </div>
  <about />
  <ROADMAP />
  <erc721a />
  <portfolio />
  <team />
  <homeFooter />
  <Music v-show="false" />
</template>

<script>
import homeHeader from "./components/homeHeader.vue";
import Hero from "./components/Hero.vue"
import About from "./components/About.vue";
import Portfolio from "./components/Works.vue";
import ROADMAP from "./components/ROADMAP.vue";
import Erc721a from "./components/Faq.vue";
import HomeFooter from "./components/HomeFooter.vue";
import Team from "./components/Team.vue";
import Music from "./components/Music.vue";

export default {
  name: "App",
  data() {
    return {
      loading: false,
      backgroundSize: "25%",
      left: 0,
      timer: 0,
      display: false,
      opacity: 1
    }
  },
  mounted: function () {
    let nowtime = new Date().getHours();
    let intervalTime = window.localStorage.getItem("isloading") == nowtime ? 1 : 500;
    let TimeOutTime = window.localStorage.getItem("isloading") == nowtime ? 1 : 2500;
    this.loading = true
    let that = this;
    let inttime = 0.01;
    let bg = setInterval(function () {
      that.backgroundSize = 25 - (inttime + 0.01) + "%";
      if (that.left >= 50) {
        clearInterval(bg);
        setTimeout(function () {
          that.loading = false;
          that.display = true;
        }, TimeOutTime)
        that.opacity = 0.8;
      }
      that.left = that.left + 2;
    }, intervalTime)
    window.localStorage.setItem("isloading", nowtime);
  },
  methods: {
    show() {
      return false;
    }
  },
  components: {
    homeHeader,
    Hero,
    About,
    ROADMAP,
    Erc721a,
    Portfolio,
    HomeFooter,
    Team,
    Music
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#app {
  height: 100%;
}
.loading {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffffff url(/img/loading.png) top left no-repeat;
  z-index: 99999999;
  background-size: 20%;
}
.loading2 {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  left: -100;
  top: -200;
  background: url(/img/loading_giraffe.png) 50% center no-repeat;
  z-index: 99999999;
}
</style>
