<template>
  <!--Service-->
  <section id="service">
    <div class="container">
      <h2 class="delay-03s animated fadeInLeft wow animated">
        <img src="/img/roadmap_tittle_4x.png" style="max-width: 30%" />
      </h2>
    </div>

    <div>
      <div class="row" style="padding-top: 50px;max-width:1910px;" >
        <div id="roadmap_pic"
          class="
            col-lg-3 col-md-3 col-sm-3
            delay-05s
            animated
            bounceInLeft
            wow
            animated
          "
          style="padding-top: 680px"
        >
          <img src="img/roadmap_pic_2x.png" />
        </div>
        <div
          class="
            col-lg-7 col-md-7 col-sm-7
            delay-02s
            animated
            bounceInUp
            wow
            animated
          "
        >
          <img
            src="/img/roadmap_pic_4x.png"
            style="width: 120px; position : absolute; top: 20px; left:-10px;"
          />

          <div class="service_wrapper">
            <!-- Q1 -->

            <span class="roadmaptitle">2022 &nbsp;&nbsp;&nbsp; Q1</span>
            <span style="padding: 8px; line-height: 100px">ROAD MAP 1.0</span>

            <div class="qtext">
              <h3>White List Session</h3>
              ●No need to keep dropping useless information!<br />
              ●No need to struggle for the art-contest!<br />
              ●Pre-sale for the first 1500 Whitelisted members, 100% fair!<br />
              ●Public sale 2 maximum purchases per wallet.<br />
              ●All the income from OpenSea goes to the Community’s wallet.<br />
            </div>
          </div>

          <!-- Q2 -->

          <div class="service_wrapper">
            <span class="roadmaptitle">2022 &nbsp;&nbsp;&nbsp; Q2</span>

            <div class="qtext">
              <h3>Airdrop</h3>
              1.Holders of 1 Common level NFT will receive a T-shirt with your
              unique NFT<br />
              2.Holders of 1 Rare Level NFT will receive a pair of Nike
              AirJordan or a Cherry keyboard with a special design including
              your unique NFT.<br />
              3.Holders of 1 Legendary Level NFT will receive an exclusive
              mystery box.<br />
            </div>
          </div>

          <!-- Q3 -->

          <div class="service_wrapper">
            <span class="roadmaptitle">2022 &nbsp;&nbsp;&nbsp; Q3</span>

            <div class="qtext">
              <h3>Community Token & Goods with Rich Gang Brand</h3>
              1.Establish a Community Token, all of the holders will be
              airdropped.<br />
              2.10% of the total income of minting will go to the initial
              liquidity pool.<br />
              3.Holders can stake their NFTs to earn tokens.<br />
              4.Specially designed jeans, jumpers, sneakers with our own brand
              IP. Can only be bought with community token.<br />
              5.Token holders can vote for the direction of the community's
              future plan.<br />
            </div>
          </div>

          <!-- Q4 -->

          <div class="service_wrapper">
            <span class="roadmaptitle">2022 &nbsp;&nbsp;&nbsp; Q4</span>

            <div class="qtext">
              <h3>More utility for NFTs and Tokens</h3>
              1.Develop an on-chain game, comic story and more! (to be decided
              by the community)<br />
              2.Collaboration with other clubs and communities.<br />
              3.Investment in other projects through the use of the community’s
              wallet, all voted by members.<br />
              4.Creation of Roadmap 2.0 with community members.<br />
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-sm-2 qbox">
          <div class="delay-01s animated bounceInUp wow animated">
            <img src="img/roadmap_Q1_4x.png" />
          </div>

          <div class="delay-02s animated bounceInUp wow animated">
            <img src="img/roadmap_Q2_4x.png" />
          </div>

          <div class="delay-03s animated bounceInUp wow animated">
            <img src="img/roadmap_Q3_4x.png" />
          </div>

          <div class="delay-04s animated bounceInUp wow animated">
            <img src="img/roadmap_Q4_4x.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Service-->
</template>