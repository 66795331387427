<template>
  <!-- Portfolio -->
  <section id="Portfolio" class="content">
    <!-- Container -->
    <div class="container portfolio_title">
      <!-- Title -->
      <div class="section-title">
        <h2 class="delay-03s animated fadeInDown wow animated">
          <img src="/img/works_tittle_4x.png" style="max-width: 30%" />
        </h2>
      </div>
      <!--/Title -->
    </div>
    <!-- Container -->

    <div class="portfolio-top"></div>

    <!-- Portfolio Filters -->
    <div class="portfolio">
      <!-- Swiper -->
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide"><img src="/img/work/1.png" /></div>
          <div class="swiper-slide"><img src="/img/work/2.png" /></div>
          <div class="swiper-slide"><img src="/img/work/3.png" /></div>
          <div class="swiper-slide"><img src="/img/work/4.png" /></div>
          <div class="swiper-slide"><img src="/img/work/5.png" /></div>
          <div class="swiper-slide"><img src="/img/work/6.png" /></div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <!--/Portfolio -->
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "HelloWorld",
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
       // effect:"coverflow",
      loop: true,
      slidesPerView: 5,
      spaceBetween: 0,
      centeredSlides: true,
      // 如果需要分页器
      autoplay: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        bulletActiveClass: 'my-bullet-activeok',
        bulletClass:'my-bullet-eok',
      }
    });
  },
};
</script>

  <!-- Initialize Swiper -->

<style scoped>

    .swiper-slide {

        text-align: center;

        height: 100%;

        display: flex;

        justify-content: center;

        align-items: center;

        transition: 300ms;

        transform: scale(0.7);

         opacity: 0.8;
    }

    .swiper-slide-active,

    .swiper-slide-duplicate-active {

       opacity: 1;

        transform: scale(1.2);
    }

    .swiper-slide-next{

        transform: scale(1);

        justify-content: right;

        padding-left: 4%;

        opacity: 0.9;

        z-index: 998 !important;

    }

    .swiper-slide-prev{

        justify-content: left;

        padding-right:4% ;

         opacity: 0.9;

        transform: scale(1);

    }

    .swiper-slide-active {

        z-index: 999 !important;

    }

</style>