<template>
  <section class="page_section team" id="team">
    <!--main-section team-start-->
    <div class="container">
    <h2><img src="/img/team_tittle_4x.png" style="max-width:30%;" /></h2>

      <div class="member-area">
        <div class="row">
          <div class="col-md-3  col-xs-6">
            <div class="member wow fadeInUp animated delay-01s">
              <div class="member-container" data-wow-delay=".1s">
                <div class="inner-container">
                  <div class="author-avatar">
                    <img
                      class="img-circle"
                      src="img/team/3.png"
                      alt="Team Menber"
                    />
                  </div>
                  <!-- /.author-avatar -->

                
                </div>
                <!-- /.inner-container -->
              </div>
              <!-- /.member-container -->
            </div>
            <!-- /.member -->
          </div>

           <div class="col-md-3 col-xs-6">
            <div class="member wow fadeInUp animated delay-01s">
              <div class="member-container" data-wow-delay=".1s">
                <div class="inner-container">
                  <div class="author-avatar">
                    <img
                      class="img-circle"
                      src="img/team/4.png"
                      alt="Team Menber"
                    />
                  </div>
                  <!-- /.author-avatar -->

                
                </div>
                <!-- /.inner-container -->
              </div>
              <!-- /.member-container -->
            </div>
            <!-- /.member -->
          </div>


           <div class="col-md-3  col-xs-6">
            <div class="member wow fadeInUp animated delay-02s">
              <div class="member-container" data-wow-delay=".1s">
                <div class="inner-container">
                  <div class="author-avatar">
                    <img
                      class="img-circle"
                      src="img/team/5.png"
                      alt="Team Menber"
                    />
                  </div>
                  <!-- /.author-avatar -->

                
                </div>
                <!-- /.inner-container -->
              </div>
              <!-- /.member-container -->
            </div>
            <!-- /.member -->
          </div>

      

           <div class="col-md-3  col-xs-6">
            <div class="member wow fadeInUp animated delay-02s">
              <div class="member-container" data-wow-delay=".1s">
                <div class="inner-container">
                  <div class="author-avatar">
                    <img
                      class="img-circle"
                      src="img/team/6.png"
                      alt="Team Menber"
                    />
                  </div>
                  <!-- /.author-avatar -->

                
                </div>
                <!-- /.inner-container -->
              </div>
              <!-- /.member-container -->
            </div>
            <!-- /.member -->
          </div>


           <div class="col-md-3  col-xs-6">
            <div class="member wow fadeInUp animated delay-02s">
              <div class="member-container" data-wow-delay=".1s">
                <div class="inner-container">
                  <div class="author-avatar">
                    <img
                      class="img-circle"
                      src="img/team/7.png"
                      alt="Team Menber"
                    />
                  </div>
                  <!-- /.author-avatar -->

                
                </div>
                <!-- /.inner-container -->
              </div>
              <!-- /.member-container -->
            </div>
            <!-- /.member -->
          </div>


           <div class="col-md-3  col-xs-6">
            <div class="member wow fadeInUp animated delay-02s">
              <div class="member-container" data-wow-delay=".1s">
                <div class="inner-container">
                  <div class="author-avatar">
                    <img
                      class="img-circle"
                      src="img/team/8.png"
                      alt="Team Menber"
                    />
                  </div>
                  <!-- /.author-avatar -->

                
                </div>
                <!-- /.inner-container -->
              </div>
              <!-- /.member-container -->
            </div>
            <!-- /.member -->
          </div>


 <div class="col-md-3  col-xs-6">
            <div class="member wow fadeInUp animated delay-03s">
              <div class="member-container" data-wow-delay=".1s">
                <div class="inner-container">
                  <div class="author-avatar">
                    <img
                      class="img-circle"
                      src="img/team/9.png"
                      alt="Team Menber"
                    />
                  </div>
                  <!-- /.author-avatar -->

                
                </div>
                <!-- /.inner-container -->
              </div>
              <!-- /.member-container -->
            </div>
            <!-- /.member -->
          </div>

        </div>
        <!-- /.row -->
      </div>
    </div>


    <!-- Footer -->


      <!--Footer-->
 
  <div  class="row footer" style="max-width:1900px">

<div class="col-md-1  col-xs-0"> </div>

  <div class="col-md-5  col-xs-12 footerImg">
    
    <div>
        <img src="img/butoon_logo.png" style="width:50%"/>
      </div>
      
     </div>

    <div class="col-md-5  col-xs-12 footText">
      
        @2022 RICHGANG.ALL RIGHT RESERVED. 

        <a href="https://twitter.com/RichGangNft">
          <img src="/img/twitter_icon_4x.png">
        </a>

        &nbsp;&nbsp;&nbsp;

        <a s href="https://discord.gg/RmR9t9cz">
          <img src="/img/discord_icon_4x.png">
        </a>

          &nbsp;&nbsp;&nbsp;

        <a s href="#">
          <img src="/img/opensea4x.png">
        </a>

          &nbsp;&nbsp;&nbsp;

        <a s href="#">
        <img src="/img/erc_looksrare4x.png">
        </a>

       </div>

      <div class="col-md-1  col-xs-0"></div>

  <div></div>

  </div> 

    <!-- End Footer -->

  </section>
  <!--/Team-->
</template>