<template>
  <!--Header_section-->
  <header id="header_wrapper">
    <div class="container">
      <div class="header_box">
        <div class="logo">
          <a href="#">
            <img src="img/logo2.png" alt="logo" />
          </a>
        </div>
        <nav class="navbar navbar-inverse" role="navigation">
          <div class="navbar-header">
            <button
              type="button"
              id="nav-toggle"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#main-nav"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div id="main-nav" class="collapse navbar-collapse navStyle">
            <ul class="nav navbar-nav" id="mainNav">
              <li class="active">
                <a href="#hero_section" class="scroll-link">Home</a>
              </li>
              <li>
                <a href="#aboutUs" class="scroll-link">About Us</a>
              </li>
              <li>
                <a href="#service" class="scroll-link">ROADMAP</a>
              </li>
              <li>
                <a href="#clients" class="scroll-link">ERC-721A</a>
              </li>
              <li>
                <a href="#Portfolio" class="scroll-link">WORKS</a>
              </li>
              <li>
                <a href="#team" class="scroll-link">Team</a>
              </li>

              <li>&nbsp;&nbsp;</li>
              <li style="margin: 0px; margin-left: 15px">
                <a href="https://twitter.com/RichGangNft" target="_blank">
                  <img src="/icon/twitter.png" />
                </a>
              </li>
              <li style="margin: 0px">
                <a s href="https://discord.com/invite/richgang" target="_blank">
                  <img src="/icon/discord.png" />
                </a>
              </li>
              <li style="margin: 0px">
                <a s href="https://opensea.io/collection/richganggenesis" target="_blank">
                  <img src="/icon/opensea.png" />
                </a>
              </li>
              <li style="margin: 0px">
                <a href="https://looksrare.org/collections/0x3E36222517Ec1413C627c69E7d56564D8A4165E5" target="_blank">
                  <img src="/icon/looksrare.png" />
                </a>
              </li>
              <li>
                <a data-toggle="dropdown"  href="#">
                  <input type="button" v-model="Loginaddress" class="ConnectButton" />
                </a>

                <div class="dropdown-menu">
                  <input
                    type="button"
                    class="ConnectButton"
                    value="Connect"
                    v-show="Connectd"
                    @click="connectWallet()"
                  />
                  <input
                    type="button"
                    class="ConnectButton"
                    @click="disconnect()"
                    v-show="disconnectd"
                    value="disconnect"
                  />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>

  <!--Hero_Section-->
  <div class="top_cont_outer_bg member wow fadeInUp animated delay-01s">
    <div class="top_cont_outer_cloud">
      <section id="hero_section" class="top_cont_outer">
        <div class="hero_wrapper">
          <div class="container">
            <div class="hero_section">
              <div style="text-align: center" class="member wow bounceInDown animated delay-01s">
                <img src="img/home_words_2x.png" style="width: 60%" />
              </div>

              <div
                style="text-align: center; padding: 68px"
                class="delay-08s animated fadeInDown wow animated"
              >
              
              <span v-show="!allowlistMintButton">
              <input type="button" value="finished" class="comingsoon" />&nbsp;
              <span v-show="disconnectd">
                
          <a href="javascript:" @click="mintAddto(1)" class="btn btn_minus" role="button" title="减少"></a>     
<input class="inputNum" v-model="mintNumber" size="1" readonly> <a href="javascript:" @click="mintAddto(2)" class="btn btn_plus" role="button" title="增加"></a></span>

</span>
                &nbsp;
                <input
                  type="button"
                  v-model="whitelistValue"
                  class="comingsoon"
                  @click="allowlistMint"
                  v-show="allowlistMintButton"
                />
              </div>

              <div v-show="this.debug" id="debug">
                <button @click="createProvider()">连接钱包主网验证</button>
                <button @click="ontest()">连接钱包测试</button>
                <button @click="mint()">Mint</button>
                <button @click="allowlistMint()">白名单Mint</button>
                {{ address }} {{ proof }}
              </div>

              <div class="row delay-01s animated fadeInUp wow animated" style="font-size: 22px; ">
                <div class="col-lg-4 col-md-4 col-sm-4"></div>
                <div class="col-lg-3 col-md-3 col-sm-3 qs">
                 
                  <br />
                  <span class="ws"></span>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 qs">
                  
                  <br />
                  <span class="ws"></span>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2"></div>
              </div>

              <div class="row">
                <div class="faceboxContent">
                  <img
                    src="img/home_man1.png"
                    style="max-width:25%"
                    class="delay-01s animated fadeInUp wow animated"
                  />

                  <img
                    src="img/home_man2.png"
                    style="max-width: 25%"
                    class="delay-02s animated fadeInUp wow animated"
                  />

                  <img
                    src="img/home_man3.png"
                    style="max-width: 25%"
                    class="delay-01s animated fadeInUp wow animated"
                  />

                  <img
                    src="img/home_man4.png"
                    style="max-width:25%"
                    class="delay-02s animated fadeInUp wow animated"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!--Hero_Section-->
</template>

<script>
import { ethers } from 'ethers';
import { BigNumber } from 'bignumber.js'
import config from '../static/config'
import axios from 'axios'
import buffer from 'buffer'
import urlencode from "urlencode";
import cutdownTimeVue from './cutdownTime.vue';
export default {
  components: {
      cutdownTimeVue
  },
  data() {
    return {
      msg: "This is demo net work",
      address: "",
      timer: "1222",
      signer: null,
      TokenAbi: config.abi,
      contractAddress: config.contractAddress,
      rootAds: config.rootAds,
      provider: "",
      instack: "6666",
      proof: [],
      debug: false,
      test: false,
      price: config.price,
      allowlistMintPrice: config.allowlistMintPrice,
      allowlistMintButton: false,
      MintLockd: true,
      buttonText: "Comming Soon",
      Loginaddress: "Connect Wallet",
      whitelistValue:"You dont is whitelist user",
      Connectd:true,
      disconnectd:false,
      mintNumber:1
    };
  },
  async mounted() {
    this.loadHttpProvider();
    this.fn();
  },
  methods: {
    isMetaMaskProvider() {
      return Boolean(window.web3 && window.ethereum.currentProvider);
    },
    async loadHttpProvider() {

      let url = "https://mainnet.infura.io/v3/d9d2909c19364f5a9e963a00d19ece1a";
      url = "https://rinkeby.infura.io/v3/a21367f875a94a93bcdf0fbdd022b073";
      let customHttpProvider = new ethers.providers.JsonRpcProvider(url);
      let contract = new ethers.Contract(this.contractAddress, this.TokenAbi, customHttpProvider);
      let instack = await contract.totalSupply();
      instack = new BigNumber(instack._hex);
      this.instack = instack;
    },

    ontest() {

      console.log(buffer);

      let pof = ['ec0b6705ac9c232991224e2e7363d80aede25632e6bf6a2096b1161d357df601', '74155250e09256176ae0bc450a493149407316b0f384493c86b890fd270fe3aa', '2bfea3a0f5343018b12af725a2def85e914248b5e8941423cc72499c5e6438c7', '6c847028f0d6311661a0c9d547f95a0fd71d7e88f46fca9fc71d589ba2c61041', '6a64c0a98f1696a2b0cda449c5e15a98bb692de5708d6aab29595070fdcead94', '7c79a562e7368e9547dc439b76e3f9ac4a93341ef3f3272cf29c5937091f8992', 'cef98be054322b66034ee91f2aac8a343a26dd5b781a77743c5083d66440e72a', '94ba5ef79c751d57f9ae6b6686dcc761d1bcb362290dda10c40307ce121c8b58', '0f5dbbb3553c6468e10f033f669c1e29581dd8d87870a00efc00895065b9c289'];

      let stringFromArray = [];
      let element = "";

      for (let index = 0; index < pof.length; index++) {
        element = pof[index];
        element = ethers.utils.id(element);
        console.log(element);
        stringFromArray.push(element);
      }

      // console.log(
      //   ethers.utils.formatBytes32String(pof)
      // );
    },

    connectWallet() {
      if (this.Loginaddress == "Connect Wallet") {
        this.createProvider();
      }
    }
    ,
    mintAddto(toclass){
        if(toclass <= 1)
        {
          this.mintNumber = this.mintNumber -1 > 1 ? this.mintNumber - 1 : 1;
          if(this.mintNumber -1 < 1)
          {
          alert("min mint 1")
          }
        }else
        {
           this.mintNumber = this.mintNumber + 1 < 8 ? this.mintNumber  + 1 : 8;
           if(this.mintNumber + 1 > 8)
           {
           alert("Max mint 8");
           }
        }
    }
    ,
    fn() {
      let that =this;
      window.ethereum.on("accountsChanged", function(accounts) {
        console.log(accounts[0]);//一旦切换账号这里就会执行
        that.disconnect();
      });
    },

    async createProvider() {
      // 基于浏览器插件MetaMask插件节点
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        if (provider.provider.chainId != "0x1") {
          alert("Network Error");
           return;
        }
        // Prompt user for account connections
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        let contract = new ethers.Contract(this.contractAddress, this.TokenAbi, provider);
        contract = new ethers.Contract(this.contractAddress, this.TokenAbi, signer);
        console.log(contract);
        this.contract = contract;
        this.address = await signer.getAddress();
        // let instack = await this.contract.functions.totalSupply();
        // instack = new BigNumber(instack).c[0];
        // this.instack = instack;
        alert(this.address);
        this.MintLockd = false;
        this.Connectd = false;
        //this.disconnectd = true;
        this.buttonText = "Mint";
        this.whitelistValue = "You dont is whitelist user";
        //this.getallowListKey(this.address);
        this.Loginaddress = this.address.substr(0, 4) + "...." + this.address.substr(this.address.length - 4, 4);
        //this.allowlistMintButton = true;
      }

      catch {
        console.log("USER_DENIED_ACCOUNT_AUTHORIZATION");
      }
    },
    async enableEth() {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        console.log(provider);
      }
    },
    async getallowListKey(wallet = "0x9e7EfC9eeBA63B0B2272A03C2E33E5c91dB7cDc6") {
      try {
        this.proof = "";
        console.log(wallet);
        wallet = buffer.Buffer.from(wallet.slice(2), "hex").toString("base64");
        wallet = urlencode(wallet);
        console.log(wallet);
        axios.get('https://lf8d031acj.execute-api.ap-east-1.amazonaws.com/api/v1/merkle_tree/leaf_exists?leaf=' + wallet + '&root=' + this.rootAds)
          .then(response => (
            console.log(response.data.proof),
            this.proof = response.data.proof,
            this.allowlistMintBtnShow())
          );
        console.log("getallowList Ok");
      } catch {
        console.log("allowList Error")
      }
    },

    bytesToHex(bytes) {
      for (var hex = [], i = 0; i < bytes.length; i++) {
        hex.push((bytes[i] >>> 4).toString(16));
        hex.push((bytes[i] & 0xF).toString(16));
      }
      return hex.join("");
    },

    allowlistMintBtnShow() {
      setTimeout(() => {
        if (this.proof.length > 0) {
          this.whitelistValue = "whitelist";
        }
      }, 1000);

    },

    async mint() {

      if (this.MintLockd) {
        this.createProvider();
        return;
      }

      if (this.address == "") {
        alert("Please connect your wallet first ");
        return;
      }

      console.log(this.contract.functions);

      // All overrides are optional
      let price = this.price * this.mintNumber;
      price = ethers.utils.parseEther(price.toString());
      let overrides = {
        // The address to execute the call as
        from: this.address,
        // The maximum units of gas for the transaction to use
        gasLimit: 150000,
        //value: ethers.utils.parseUnits("0.1", 12).toNumber()
        value: price

      };
      await this.contract.functions.publicSaleMint(this.mintNumber.toString(), config.key, overrides).then(async (res) => {
        console.log(res);
      });

    },

    async allowlistMint() {
      if (this.address == "" || this.proof == "") {
        alert(" You are not a whitelist user ");
        return;
      }
      console.log(this.contract.functions);
      // All overrides are optional
      let price = ethers.utils.parseEther(this.allowlistMintPrice);
      let overrides = {
        // The address to execute the call as
        from: this.address,
        // The maximum units of gas for the transaction to use
        gasLimit: 150000,
        //value: ethers.utils.parseUnits("0.1", 12).toNumber()
        value: price
      };

      let stringFromArray = [];
      let element = "";

      for (let index = 0; index < this.proof.length; index++) {
        element = this.proof[index];
        //element = ethers.utils.id(element);
        element = "0x" + element;
        stringFromArray.push(element);
      }

      await this.contract.functions.allowlistMint(stringFromArray, overrides).then(async (res) => {
        console.log(res);
      });

    }
    ,
    ondebug() {
      this.debug = true;
    },
    returnAddress() {
      return this.address;
    },
    disconnect() {
      this.MintLockd = true;
      this.address = "";
      this.Loginaddress = "Connect Wallet";
      this.buttonText= "Comming Soon";
      alert("disconnected");
      this.disconnectd = false;
      this.Connectd = true;
      this.allowlistMintButton = false;
    }
  }
}
</script>
