<template>
  <section class="page_section" id="clients">
    <div class="row" style="max-width:99%">
       <div class="col-lg-10 col-md-10 col-sm-10 erc721aText">

<h1 class="delay-01s animated fadeInUp wow animated">ERC-721A <img src="/img/erc_line_2x.png" width="30%" /></h1>

<div class="delay-01s animated fadeInUp wow animated">ERC721A is an implementation of ERC721 that allows its users to save on gas fees when minting multiple NFTs in a single transaction.One of Ethereum blockchain's biggest issues is Gas fees.NFTs and other projects developed on the Ethereum blockchain need to adaptto new technology in order to reduce gas fees that have been way too high for months.</div>

<div class="delay-01s animated fadeInUp wow animated">The classic process doesn't allow it, so we decided to use ERC721A technology which allows us to mint several NFTs for gas feecequivalent to minting a single NFT.</div>

<div class="delay-02s animated fadeInUp wow animated">We wanted to optimize this contract so that all our members can invest their money on better terms for them.We ensured that ourblockchain developer has this technology and is completely safe.Our goal is to facilitate the process for our community.</div>

<div class="delay-02s animated fadeInUp wow animated">The ERC721A technology allows the acquisition of multiple NFTs in a single transaction and this is something we encourage.</div>
</div>

 <div class="col-lg-2 col-md-2 col-sm-2 erc721aImg">
   <img src="/img/erc_pic_2x.png" style="width:80%; margin-top:18vw;" />
   </div>
 </div>

<div class="ercfooter"></div>

  </section>
  <!--client_logos-->
</template>