<template>
  <section id="aboutUs">
    <!--Aboutus-->
    <div class="inner_wrapper">
      <div class="container">
        <h2  class="delay-01s animated fadeInDown wow animated"><img src="/img/about_tittle_4x.png" style="max-width:30%" /></h2>
        <div class="inner_section">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pull-left">
              <div>
                <h3  class="delay-01s animated fadeInUp wow animated"> // Rich gang is an online community</h3>
                <br />
                <p
                class="delay-01s animated fadeInUp wow animated">
                We have a collection of 6,666 NFTs which are unique and easily traded through the use of the Ethereum Network.<br />

Ownership is safe and secure thanks to the IPFS storage system.The concept was derived by an independent Artist as well as a group of native crypto users. </p>
<p  class="delay-01s animated fadeInUp wow animated"><br />
  For our first NFT project, we are on a mission to build a fantastic community! We will achieve this through the use of Web3 blockchain technology while also developing an original brand. We plan to provide community members the opportunity of owning not only the cool PFPs but also their own unique and personalised goods.
</p>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Aboutus-->
</template>