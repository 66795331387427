<template>
  <!--Header_section-->
  <header id="header_wrapper">
    <div class="container">
      <div class="header_box">
        <div class="logo">
          <a href="#"><img src="img/logo2.png" alt="logo" /></a>
        </div>
        <nav class="navbar navbar-inverse" role="navigation">
          <div class="navbar-header">
            <button
              type="button"
              id="nav-toggle"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#main-nav"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span> <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div id="main-nav" class="collapse navbar-collapse navStyle">
            <ul class="nav navbar-nav" id="mainNav">
              <li class="active">
                <a href="#hero_section" class="scroll-link">Home</a>
              </li>
              <li><a href="#aboutUs" class="scroll-link">About Us</a></li>
              <li><a href="#service" class="scroll-link">ROADMAP</a></li>
              <li><a href="#clients" class="scroll-link">ERC-721A</a></li>
              <li><a href="#Portfolio" class="scroll-link">WORKS</a></li>
              <li><a href="#team" class="scroll-link">Team</a></li>

              <li>&nbsp;&nbsp;</li>
              <li style="margin: 0px; margin-left: 15px">
                <a href="https://twitter.com/RichGangNft" target="_blank">
                  <img src="/icon/twitter.png">
                </a>
              </li>
              <li style="margin: 0px">
                <a s href="https://discord.gg/RmR9t9cz"  target="_blank">
                 <img src="/icon/discord.png">
                </a>
              </li>
                <li style="margin: 0px">
                <a s href="#"  target="_blank">
                   <img src="/icon/opensea.png">
                   </a>
              </li>
                <li style="margin: 0px">
                  <a href="#">
               <img src="/icon/looksrare.png">
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
  <!--Header_section-->
</template>




